import { render, staticRenderFns } from "./createTwo.vue?vue&type=template&id=28975f11&scoped=true&"
import script from "./createTwo.vue?vue&type=script&lang=js&"
export * from "./createTwo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28975f11",
  null
  
)

export default component.exports