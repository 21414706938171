<template>
  <div class="paddingTop20" id="createThree">
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore">
      <template slot="h3">
        <div></div>
      </template>

      <template slot="phone">
        <el-input v-model="form.phone" type="text" maxlength="11"
                  placeholder="请输入 手机号" clearable show-word-limit
                  :disabled="disabled"
                  onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')"
                  @blur="changeBlur($event,'phone')">
        </el-input>
      </template>

    </avue-form>
  </div>
</template>

<script>
export default {
  name: "createThree",
  data(){
    return{
      disabled: this.$route.query.type === 'view',
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: this.$route.query.type === 'view',
        column: [
          {
            label: '房源图片上传',
            prop: 'images',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传9张，建议尺寸：750*560',
            fileSize: 20 * 1024,
            span: 24,
            limit: 9,
            // multiple: true,
            rules: [{
              required: true,
              message: "请上传房源图片",
              trigger: "blur"
            }]
          },
          {
            label: '房主身份证信息',
            prop: 'h3',
          },
          {
            label: '房主姓名',
            prop: 'houseOwner',
            maxlength: 6,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入房主姓名",
              trigger: "blur"
            }]
          },
          {
            label: '手机号',
            prop: 'phone',
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入手机号",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(!/^1[3456789]\d{9}$/.test(value)){
                  callback(new Error('请输入正确的手机号'));
                }else{
                  callback();
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '身份证号',
            prop: 'idCard',
            maxlength: 18,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入身份证号",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value))) {
                  callback(new Error('请输入正确的身份证号码'));
                } else {
                  callback();
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '房主身份证正反面',
            prop: 'idCardFront',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            tip: '建议尺寸：336*224',
            action: this.actionUrl,
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传房主身份证正面",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'idCardBack',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            tip: '建议尺寸：336*224',
            action: this.actionUrl,
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传房主身份证反面",
              trigger: "blur"
            }]
          },
          {
            label: '房本上传',
            prop: 'premisesPermit',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            loadText: '上传中，请稍等',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '最多上传3张，建议尺寸：184*184',
            fileSize: 20 * 1024,
            span: 24,
            limit: 3,
            // multiple: true,
            rules: [{
              required: true,
              message: "请上传房本图片",
              trigger: "blur"
            }]
          }
        ]
      },
      uploadList: [], //上传图片数据列表
      form: {},
    }
  },
  mounted() {
    this.uploadList = this.formOption.column.filter(item => item.type === 'upload'); //获取上传组件
  },
  methods: {
    async setForm(form) {
      // console.log(form)
      // console.log(this.uploadList)
      const images = this.findObject(this.formOption.column, 'images');
      const premisesPermit = this.findObject(this.formOption.column, 'premisesPermit');
      images.propsHttp.name = form.images
      premisesPermit.propsHttp.name = form.premisesPermit
      this.uploadList.forEach(item => {
        this.formOption.column[item.prop] = form[item.prop]
        if(form[item.prop] !== '' && (item.prop === 'images' || item.prop === 'premisesPermit')){
          const limit = form[item.prop].split(',').length;
          if(limit === item.limit){
            this.upload('none',item.prop);
          }
        }
      })
      this.form = {
        ...form
      };
    },
    formSubmit(){
      return new Promise(resolve => {
        this.$refs.form.validate((valid,done) => {
          if (valid) {
            done();
            resolve(this.form)
          } else {
            done();
            resolve(false)
          }
        });
      })
    },
    changeBlur(e,text){
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          this.form[text] = value
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          // console.log(action)
          if(action === 'confirm'){
            let array = JSON.parse(JSON.stringify(this.form[column.prop].split(',')));
            let index = array.findIndex(item=>item === file.url)
            array.splice(index,1);
            column.propsHttp.name = array.toString();
            if(array.length < column.limit){
              this.upload('inline-block',column.prop);
            }
          }
          done();
        }
      })
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        if(column.prop === 'images' || column.prop === 'premisesPermit'){
          if(column.propsHttp.name){
            column.propsHttp.name += ',' + res.path
          }else{
            column.propsHttp.name = res.path;
          }
        }else{
          column.propsHttp.name = res.path;
        }
        if(column.propsHttp.name.split(',').length >= column.limit) {
          this.upload('none',column.prop);
        }
      }
    },
    upload(type,prop){
      let index = this.uploadList.findIndex(item => item.prop === prop)
      console.log(index)
      let upload = document.getElementById('createThree').getElementsByClassName('el-upload')[index];
      upload.style.display = type === 'none' ? 'none' : 'inline-block';
    },
  }
}
</script>

<style scoped>
/deep/ .avue-form__group .avue-upload__icon{
  width: 148px !important;
  height: 148px !important;
  line-height: 148px !important;
}
/deep/ .avue-form__group .avue-upload__avatar{
  width: 148px !important;
  height: 148px !important;
}
</style>