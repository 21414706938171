<template>
  <div class="paddingTop20">
    <avue-form ref="form"
               v-model="form"
               :option="formOption">
    </avue-form>
  </div>
</template>

<script>
export default {
  name: "createTwo",
  data(){
    return{
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: this.$route.query.type === 'view',
        column: [
          {
            label: '朝向',
            prop: 'orientation',
            type: 'checkbox',
            dicData: [
              {
                label: '东',
                value: 1
              },
              {
                label: '南',
                value: 2
              },
              {
                label: '西',
                value: 3
              },
              {
                label: '北',
                value: 4
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择朝向",
              trigger: "blur"
            }]
          },
          {
            label: '楼层',
            prop: 'floor',
            type: 'radio',
            dicData: [
              {
                label: '顶层',
                value: 1
              },
              {
                label: '高',
                value: 2
              },
              {
                label: '中',
                value: 3
              },
              {
                label: '低',
                value: 4
              },
              {
                label: '底层',
                value: 5
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择楼层",
              trigger: "blur"
            }]
          },
          {
            label: '装修',
            prop: 'decorate',
            type: 'radio',
            dicData: [
              {
                label: '精装',
                value: 1
              },
              {
                label: '简装',
                value: 2
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择装修",
              trigger: "blur"
            }]
          },
          {
            label: '电梯',
            prop: 'elevator',
            maxlength: 6,
            showWordLimit: true,
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请输入电梯",
              trigger: "blur"
            }]
            // type: 'radio',
            // dicData: [
            //   {
            //     label: '无',
            //     value: 1
            //   },
            //   {
            //     label: '有',
            //     value: 2
            //   }
            // ],
            // span: 13,
            // row: true,
            // rules: [{
            //   required: true,
            //   message: "请选择有无电梯",
            //   trigger: "blur"
            // }]
          },
          {
            label: '楼型',
            prop: 'buildType',
            type: 'radio',
            dicData: [
              {
                label: '板楼',
                value: 1
              },
              {
                label: '塔楼',
                value: 2
              },
              {
                label: '板塔结合',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择楼型",
              trigger: "blur"
            }]
          },
          {
            label: '用途',
            prop: 'purpose',
            type: 'radio',
            dicData: [
              {
                label: '普通住宅',
                value: 1
              },
              {
                label: '办公',
                value: 2
              },
              {
                label: '商住',
                value:3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择用途",
              trigger: "blur"
            }]
          },
          {
            label: '权属',
            prop: 'ownership',
            type: 'radio',
            dicData: [
              {
                label: '个人单独产权',
                value: 1
              },
              {
                label: '个人多人产权',
                value: 2
              },
              {
                label: '公司产权',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择权属",
              trigger: "blur"
            }]
          },
          {
            label: '抵押情况',
            prop: 'mortgage',
            type: 'radio',
            dicData: [
              {
                label: '无抵押',
                value: 1
              },
              {
                label: '只有银行抵押',
                value: 2
              },
              {
                label: '有银行及其他抵押',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择抵押情况",
              trigger: "blur"
            }]
          },
          {
            label: '供暖方式',
            prop: 'heatMode',
            type: 'radio',
            dicData: [
              {
                label: '无',
                value: 1
              },
              {
                label: '自供暖',
                value: 2
              },
              {
                label: '集中供暖',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择供暖方式",
              trigger: "blur"
            }]
          },
          {
            label: '房本年限',
            prop: 'ageLimit',
            type: 'radio',
            dicData: [
              {
                label: '不满2年',
                value: 1
              },
              {
                label: '满2年',
                value: 2
              },
              {
                label: '满5年',
                value: 3
              }
            ],
            span: 13,
            row: true,
            rules: [{
              required: true,
              message: "请选择房本年限",
              trigger: "blur"
            }]
          },
          {
            label: '上次交易',
            prop: 'lastTime',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            span: 13,
            row: true,
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              }
            },
            rules: [{
              required: true,
              message: "请选择上次交易时间",
              trigger: "blur"
            }]
          }
        ]
      },
      form: {}
    }
  },
  methods: {
    async setForm(form) {
      // console.log(form)
      const { orientation, ...rest } = form
      const orientationArr = orientation.split(',').map(item => {
        return Number(item)
      })
      // console.log(orientationArr)
      this.form = {
        ...rest,
        orientation: orientationArr
      };
    },
    formSubmit(){
      return new Promise(resolve => {
        this.$refs.form.validate((valid,done) => {
          if (valid) {
            done();
            resolve(this.form)
          } else {
            done();
            resolve(false)
          }
        });
      })
    },
  }
}
</script>

<style scoped>

</style>