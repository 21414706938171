<template>
  <div class="paddingTop20">
    <avue-form ref="form"
               v-model="form"
               :option="formOption">
      <template slot="one">
        <el-button style="background: #c280ff;color: #ffffff;border-color: #c280ff" @click="viewAgreement">查看协议</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { viewContract } from "@/api/house/second"
export default {
  name: "createFour",
  data(){
    return{
      form: {
        contractId: '',
        signType: '',
        signTypeImage: ''
      },
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: this.$route.query.type === 'view',
        column: [
          {
            label: '签约协议',
            prop: 'signType',
            type: 'radio',
            span: 13,
            row: true,
            dicData: [
              {
                label: '线上签订',
                value: 1
              },
              {
                label: '手动上传授权委托书',
                value: 2
              }
            ]
          },
          {
            label: '',
            prop: 'one',
            span: 13,
            row: true,
            display: false,
          },
          {
            label: '',
            prop: 'signTypeImage',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            span: 24,
            display: false,
          }
        ]
      }
    }
  },
  mounted() {
    // let upload = document.getElementsByClassName('el-upload')[0];
    // upload.style.display = 'none';
  },
  methods: {
    async setForm(form) {
      // console.log(form)
      this.form = {
        ...form
      };
      const one = this.findObject(this.formOption.column, 'one');
      const signTypeImage = this.findObject(this.formOption.column, 'signTypeImage');
      if(form.signType === 1){
        one.display = true;
        signTypeImage.display = false;
      }else if(form.signType === 2){
        one.display = false;
        signTypeImage.display = true;
      }else{
        one.display = false;
        signTypeImage.display = false;
      }
    },
    async viewAgreement(){
      const { data } = await viewContract({
        id: this.form.contractId
      })
      // console.log(data)
      window.open(data.pageUrl,'_blank')
    }
  }
}
</script>

<style scoped>

</style>